import payload_plugin_TMXldCklHx from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.30.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_xg5nY0r7pl from "/vercel/path0/node_modules/.pnpm/@pinia+colada-nuxt@0.0.4_@pinia+colada@0.13.2_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescr_lw3euxv5247xjsdfsjyp3jggk4/node_modules/@pinia/colada-nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_hbh5AY3ekc from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GbbwMG1mRC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PwgsIzLNUk from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_H0toyFCtGH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oj1RHhsXzw from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_owxlIpmZfm from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_egNhLNQxaG from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_JS1IZlrp9y from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.30.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_zbacBAXhj0 from "/vercel/path0/apps/zulassung-digital/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/zulassung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_qQwIGtDWHL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.2.1_eslint@_2zno25a35ssdjdfjt2gvtgmfda/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t79ZjqHlF1 from "/vercel/path0/node_modules/.pnpm/@pinia+colada-nuxt@0.0.4_@pinia+colada@0.13.2_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescr_lw3euxv5247xjsdfsjyp3jggk4/node_modules/@pinia/colada-nuxt/dist/runtime/plugin.js";
import slideovers_QZuzlKMtc6 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.20.0_async-validator@4.2.5_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast_bz2csfiyrj2brztksh7gizfpym/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_SPnuqTs9SK from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.20.0_async-validator@4.2.5_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast_bz2csfiyrj2brztksh7gizfpym/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_XhCQAUIcIE from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.20.0_async-validator@4.2.5_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast_bz2csfiyrj2brztksh7gizfpym/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ZVojjuzKpF from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_TPBYIkE0tQ from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.0_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_sass_hhifwiikzusshq34xjl2o6zsiy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_YpsIKJpNXM from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescri_w2xn73jleycukd552zqq57pphu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import feathers_r9RHv5VuHz from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_ZWaWEo2Woe from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_EGbfpheBQT from "/vercel/path0/layers/base/plugins/trpc.ts";
import wartungsarbeiten_client_wVkIgb3gzE from "/vercel/path0/layers/base/plugins/wartungsarbeiten.client.ts";
import event_directive_YCbI7d1p4o from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_JCMvpbgZ2W from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import empire_link_client_UgDYuXu5sA from "/vercel/path0/apps/zulassung-digital/plugins/empire-link.client.ts";
import hotjar_client_HJG8no80GI from "/vercel/path0/apps/zulassung-digital/plugins/hotjar.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/apps/zulassung-digital/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/zulassung-digital/plugins/sentry.client.ts";
export default [
  payload_plugin_TMXldCklHx,
  payload_plugin_xg5nY0r7pl,
  revive_payload_client_hbh5AY3ekc,
  unhead_GbbwMG1mRC,
  router_PwgsIzLNUk,
  payload_client_H0toyFCtGH,
  navigation_repaint_client_oj1RHhsXzw,
  check_outdated_build_client_owxlIpmZfm,
  chunk_reload_client_egNhLNQxaG,
  plugin_vue3_JS1IZlrp9y,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qQwIGtDWHL,
  plugin_t79ZjqHlF1,
  slideovers_QZuzlKMtc6,
  modals_SPnuqTs9SK,
  colors_XhCQAUIcIE,
  plugin_client_ZVojjuzKpF,
  plugin_TPBYIkE0tQ,
  plugin_YpsIKJpNXM,
  feathers_r9RHv5VuHz,
  cookie_banner_client_ZWaWEo2Woe,
  trpc_EGbfpheBQT,
  wartungsarbeiten_client_wVkIgb3gzE,
  event_directive_YCbI7d1p4o,
  script_JCMvpbgZ2W,
  empire_link_client_UgDYuXu5sA,
  hotjar_client_HJG8no80GI,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]